import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Grid } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import '@aws-amplify/ui-react/styles.css';
import {AmplifyProvider, Authenticator, createTheme, defaultTheme} from '@aws-amplify/ui-react';
import useSettings from "../../hooks/useSettings";
import {Hub} from "aws-amplify";
import {useEffect} from "react";
import "./style.css"
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const theme = createTheme({
    name: 'dark-mode-theme',
    overrides: [
      {
        colorMode: 'dark',
        tokens: {
          colors: {
            neutral: {
              // flipping the neutral palette
              10: defaultTheme.tokens.colors.neutral[100],
              20: defaultTheme.tokens.colors.neutral[90],
              40: defaultTheme.tokens.colors.neutral[80],
              80: defaultTheme.tokens.colors.neutral[40],
              90: defaultTheme.tokens.colors.neutral[20],
              100: defaultTheme.tokens.colors.neutral[10],
            },
            black: {value: '#fff'},
            white: {value: '#000'},
          },
        },
      },
    ],
  });
  const mode = useSettings().themeMode;
  useEffect(() => {
    Hub.listen('auth', ({payload: {event, data}}) => {
      console.log(event);
      switch (event) {
        case 'signIn':
          console.log('Logged');
          break;
      }
    });

  }, []);

  
  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
        
        </HeaderStyle>

        
        
        
          <Grid container >
            <Grid item xs={6} sm={6} className="login-left-card">
                <Box className="login-left-card-content">
                    <Box component="img" src="\logo\burns.svg" alt="" />
                    <Typography variant="h3" sx={{lineHeight:'35px',mt:5}}>
                    Welcome to the Burns Skills School Portal
                  </Typography>

                  <Typography sx={{marginTop:'3em'}} paragraph>
                    Access Lessons and Resources
                  </Typography>
                </Box>
            </Grid>

            <Grid item xs={6} sm={6} className="login-right-card">
                <Box className="right-card">
                  <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h4" gutterBottom> Log in</Typography>
                      <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                    </Box>
                  </Box>
                  <LoginForm />                               
                </Box>
            </Grid>

          </Grid>         
      </RootStyle>
    </Page>
  );
}
