import {Suspense, lazy, ElementType} from 'react';
import {Navigate, useRoutes, useLocation, Outlet} from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import {PATH_AFTER_LOGIN} from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import OrganizationManage from 'src/pages/dashboard/organization/OrganizationManage';
import OrganizationOutlet from "../pages/dashboard/organization/OrganizationOutlet";
import ContentStore from "../pages/dashboard/organization/content_store/ContentStore";
import TestPage from "../components/TestPage";
import Feedback from "../components/feedback/Feedback";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {pathname} = useLocation();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {isAuthenticated} = useAuth();

    const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

    return (
        <Suspense fallback={<LoadingScreen isDashboard={isDashboard}/>}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login/>
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register/>
                        </GuestGuard>
                    ),
                },
                {path: 'login-unprotected', element: <Login/>},
                {path: 'register-unprotected', element: <Register/>},
                {path: 'reset-password', element: <ResetPassword/>},
                {path: 'verify/:email', element: <VerifyCode/>},
            ],
        },

        // Dashboard Routes
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
                /*                {path: 'app', element: <GeneralApp/>},
                                {path: 'ecommerce', element: <GeneralEcommerce/>},
                                {path: 'analytics', element: <GeneralAnalytics/>},
                                {path: 'banking', element: <GeneralBanking/>},
                                {path: 'booking', element: <GeneralBooking/>},*/

                /*                {
                                    path: 'e-commerce',
                                    children: [
                                        {element: <Navigate to="/dashboard/e-commerce/shop" replace/>, index: true},
                                        {path: 'shop', element: <EcommerceShop/>},
                                        {path: 'product/:name', element: <EcommerceProductDetails/>},
                                        {path: 'list', element: <EcommerceProductList/>},
                                        {path: 'product/new', element: <EcommerceProductCreate/>},
                                        {path: 'product/:name/edit', element: <EcommerceProductCreate/>},
                                        {path: 'checkout', element: <EcommerceCheckout/>},
                                        {path: 'invoice', element: <EcommerceInvoice/>},
                                    ],
                                },*/
                {path: 'test', element: <TestPage/>},
                {
                    path: 'user',
                    children: [
                        {element: <Navigate to="/dashboard/user/profile" replace/>, index: true},
                        {path: 'profile', element: <ChildProfile/>},
                        {path: 'account', element: <UserAccount/>},
                        {path: 'account/organization/new', element: <CreateOrganization/>},
                        {path: 'cards', element: <UserCards/>},
                        {path: 'list', element: <UserList/>},
                        {path: 'new', element: <UserCreate/>},
                        {path: ':name/edit', element: <UserCreate/>},
                        {path: 'account', element: <UserAccount/>},
                        {path: 'account/organization/new', element: <CreateOrganization/>}
                    ],
                },
                {
                    path: 'child',
                    children: [
                        {path: ':pupilId', element: <ChildProfile/>},
                    ],
                },
                {
                    path: 'contentStore',
                    element: <ContentStore/>,
                },
                {
                    path: 'organization',
                    element: <OrganizationOutlet/>,
                    children: [
                        {path: '', element: <OrganizationsGrid/>},
                        {path: ':organizationId', element: <SectionOverview/>},



                        {path: ':organizationId/wearables', element: <WearablesDashboard/>,},
                        {path : ':organizationId/assessment',element:<UserAssessments/>},
                        {path : ':organizationId/report',element:<UserAssessmentRecords/>},
                        {path: ':organizationId/dashboard', element: <OrganizationDashboard/>},
                        {path: ':organizationId/activity', element: <ActivityDashboard/>,},
                        {path: ':organizationId/activity/:userId', element: <MemberActivityOverview/>},
                        {path: ':organizationId/manage', element: <OrganizationManage/>},
                        {path: ':organizationId/manage/team/:teamId', element: <TeamManageOverview/>},
                        {path: ':organizationId/section/:sectionId', element: <SectionOverview/>},
                        {path: ':organizationId/section/:sectionId/lesson/new', element: <LessonNew/>},
                        {path: ':organizationId/section/:sectionId/lesson/:lessonId', element: <LessonOerview/>}

                    ],
                },
/*                                {
                                    path: 'blog',
                                    children: [
                                        {element: <Navigate to="/dashboard/blog/posts" replace/>, index: true},
                                        {path: 'posts', element: <BlogPosts/>},
                                        {path: 'post/:title', element: <BlogPost/>},
                                        {path: 'new-post', element: <BlogNewPost/>},
                                    ],
                                },
                                {
                                    path: 'mail',
                                    children: [
                                        {element: <Navigate to="/dashboard/mail/all" replace/>, index: true},
                                        {path: 'label/:customLabel', element: <Mail/>},
                                        {path: 'label/:customLabel/:mailId', element: <Mail/>},
                                        {path: ':systemLabel', element: <Mail/>},
                                        {path: ':systemLabel/:mailId', element: <Mail/>},
                                    ],
                                },
                                {
                                    path: 'chat',
                                    children: [
                                        {element: <Chat/>, index: true},
                                        {path: 'new', element: <Chat/>},
                                        {path: ':conversationKey', element: <Chat/>},
                                    ],
                                },*/
                                {path: 'calendar', element: <Calendar/>},
                                {path: 'feedback', element: <Feedback/>},
                                {path: 'kanban', element: <Kanban/>},
            ],
        },

        // Main Routes
        {
            path: '*',
            element: <LogoOnlyLayout/>,
            children: [
                {path: 'coming-soon', element: <ComingSoon/>},
                {path: 'maintenance', element: <Maintenance/>},
                {path: 'pricing', element: <Pricing/>},
                {path: 'payment', element: <Payment/>},
                {path: '500', element: <Page500/>},
                {path: '404', element: <NotFound/>},
                {path: '*', element: <Navigate to="/404" replace/>},
            ],
        },
        {
            path: '/',
            element: <Navigate to={'dashboard'}/>,
            children: [
                {element: <HomePage/>, index: true},
                {path: 'about-us', element: <About/>},
                {path: 'contact-us', element: <Contact/>},
                {path: 'faqs', element: <Faqs/>},
            ],
        },
        {path: '*', element: <Navigate to="/404" replace/>},
    ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(
    lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
);
const EcommerceProductList = Loadable(
    lazy(() => import('../pages/dashboard/EcommerceProductList'))
);
const EcommerceProductCreate = Loadable(
    lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
);
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const ChildProfile = Loadable(lazy(() => import('../pages/dashboard/parent/child/ChildProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const OrganizationDashboard = Loadable(lazy(() => import('../pages/dashboard/organization/OrganizationDashboard')));
const MemberActivityOverview = Loadable(lazy(() => import('../pages/dashboard/organization/member/MemberActivityOverview')));
const WearablesDashboard = Loadable(lazy(() => import('../pages/dashboard/organization/WearablesDashboard')));
const ActivityDashboard = Loadable(lazy(() => import('../pages/dashboard/organization/ActivityDashboard')));
const SectionOverview = Loadable(lazy(() => import( "../components/section/SectionOverview")));
const LessonOerview = Loadable(lazy(() => import( "../components/section/lesson/LessonOerview")));
const CreateOrganization = Loadable(lazy(() => import( "../pages/dashboard/user/CreateOrganization")));
const OrganizationsTable = Loadable(lazy(() => import( "../pages/dashboard/user/OrganizationsTable")));
const OrganizationsGrid = Loadable(lazy(() => import( "../pages/dashboard/user/OrganizationsGrid")));
const TeamManageOverview = Loadable(lazy(() => import( "../pages/dashboard/organization/team/TeamManageOverview")));
const LessonNew = Loadable(lazy(() => import( "../pages/dashboard/user/LessonNew")));
const UserAssessments = Loadable(lazy(() => import("../pages/dashboard/assessments/UserAssessments")));
const UserAssessmentRecords = Loadable(lazy(() => import("../pages/dashboard/assessments/UserAssessmentList")));