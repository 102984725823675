/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:f741df3a-0df7-4759-a8d7-4f1270bea0fc",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_v5CzGdnVr",
    "aws_user_pools_web_client_id": "hqt941fkl4rp6ml22k92ftahl",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://5qrx3hnrlvfzrmkbnp3yui35ue.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-3se47clocjaa5ha2nsxw76ufge",
    "aws_cloud_logic_custom": [
        {
            "name": "HealthyHabitsV2API",
            "endpoint": "https://msiemijnx1.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "healthy-habits-v2-content205646-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
