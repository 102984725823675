import React from 'react';

const Feedback = () => {
    return (
        <>
        <iframe style={{
            border: "0px",
            height: "100vh",
            width: "100%",
        }} src='https://healthcare-analytics.hellonext.co/embed/b/feedback'/>
        </>

    );
};

export default Feedback;
